.c-video {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.c-video-source {
    position: absolute;
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    left: 0;
}

.c-video-overlay, .c-video-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.c-video-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.c-video-content-title {
    text-align: center;
    color: $color-white;
    margin-top: 30px;
}

.c-video-content-desc {
    text-align:center;
    color: $color-white;
}

.c-video-content-play {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 100;
    letter-spacing: 2px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;

    a {
        color: $color-white;
        border: 1px solid $color-white;
        padding: 15px 25px;
        transition: all .3s ease-in-out;
        border-radius: 40px;

        &:hover {
            background: $color-white;
            color: $color-black;
        }
    }
}