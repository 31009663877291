.c-header-slider {
  position: relative;

  height: calc(60vh + 60px);
  padding-top: 60px;

  @include bp-medium() {
    height: calc(60vh + 80px);
    padding-top: 80px;
  }
}

.c-header-slider__item {
  position: relative;

  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.c-header-slider__wrapper {
  > div > div {
    height: 60vh;
  }
}

.c-header-slider__box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 1000px;
  padding: 0 15px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header-slider__title {
  color: $color-white;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 20px;

  p {
    margin: 0;
  }
}

.c-header-slider__arrow {
  color: $color-red;

  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  background: $color-grey;
  width: 40px;
  height: 40px;

  font-size: 1.4rem;
  font-weight: 700;

  opacity: 0.6;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s opacity ease-in;

  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &--left {
    margin-top: 30px;
    @include bp-medium() {
      margin-top: 40px;
    }
    left: 10px;
  }

  &--right {
    margin-top: 30px;
    @include bp-medium() {
      margin-top: 40px;
    }
    right: 10px;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}