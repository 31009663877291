.c-partner-sites {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;

  @include bp-medium() {
    padding: 0 100px;
  }

  + .alm-btn-wrap {
    text-align: center;

    .u-show-more--text {
      color: $color-black !important;
    }
  }
}

.c-partner-sites-item-wrap {
    @include clearfix();
}

.c-partner-sites-item {
  margin-top: 80px;
}

.c-partner-sites-item-icon {
  width: 60px;
  float: left;
}

.c-partner-sites-item-title {
  width: auto;
  float: left;
  text-transform: uppercase;
  color: $color-red;
  font-weight: 700;
  padding-left: 13px;
  padding-top: 5px;
}

.c-partner-sites-item-text {
  padding-top: 10px;

  .c-btn {
      margin-top: 10px;
  }
}
