.demo-content {
    padding: 100px 20px;
    max-width: 1200px;
    margin: 0 auto;

    video {
        width: 100%;
    }
}

.demo-media {
    padding: 5px 0;
    max-width: 500px;

    video {
        max-width: 400px;
    }
}

.demo-media-title {
    font-weight: 700;
}
