/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
}

.special-hr {
  margin: 0;
}