/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  transition: $global-transition;
  border: 1px solid;
  border-radius: $global-radius;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-link;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
  }
}

.c-btn--gallery {
  font-size: 1rem;
  border-radius: 12px;
  padding: 6px 18px;
  text-transform: uppercase;
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.15rem 1.3rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* Border color variants
   ========================================================================== */

.c-btn--white {
  border-color: $color-white;
  color: $color-white;
  transition: all 0.3s ease-in;

  &:hover {
    background: $color-white;
    color: $color-black;
  }
}

.c-btn--red {
  color: $color-red;
  border-color: $color-red;

  a {
    color: $color-red;
  }
  &:hover {
    background: $color-red;
    color: $color-white;
    border-color: $color-red;
    a {
      color: $color-white;
    }
  }
}

.c-btn--red-inverse {
  background: $color-red;
  color: $color-white;
  border-color: $color-red;
  a {
    color: $color-white;
  }

  &:hover {
    background: transparent;
    color: $color-red;
    border-color: $color-red;

    a {
      color: $color-red;
    }
  }
}

.c-btn-go-back-wrap {
  width: 100%;
  text-align: center;
}

.c-btn-go-to-website {
  text-align: center;
  display: inline-block;
}

.big-red-arrow {
  font-size: 24px;
  font-weight: 700;
  color: $color-red;
  display: inline-block;
}
// Load more BTN


