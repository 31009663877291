/* ==========================================================================
   #TOPBAR
   ========================================================================== */

.c-topbar {
    height: 60px;
    background: $color-lighter-black;
    @include clearfix();
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 3;

    @include bp-medium() {
        height: 80px;
    }
}

.c-topbar-nav {
    background: $color-red;
    width: 80px;
    height: 100%;
    position: relative;
    display: flex;

    @include bp-medium() {
        width: 95px;
    }
}

.c-topbar-logo {
    display: flex;
    position: absolute;
    height: 100%;
    width: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include bp-medium() {
        width: 75px;
    }
}

.c-topbar-lang {
    color: $color-white;
    margin-left: 20px;
    font-size: 0.9em;
    position:absolute;
    right: 20px;

    @include bp-small() {
        position: relative;
        margin-left: 20px;
        right: auto;
    }

    a {
        color: $color-white;
    }
    .left-arrow-red {
        width: 12px;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: 2px;
        // display: none;
    }
    span {
        font-weight: 300;
        display: none;
    }
    @include bp-medium {
        .left-arrow-red, span {
            display: inline-block;
        }
    }
}

.c-topbar__right-part {
    display: none;

    @include bp-small() {
        display: flex;
        margin-left: auto;
        margin-right: 20px;
    }
}

.c-topbar-family-sites {
    position: relative;
    margin-right: 30px;

    &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 115%;
        top: 0;
        background: #fff;
        right: -15px;
    }

    .shop-icon {
        width: 20px;
        @include bp-medium() {
            width: 24px;
        }
    }
}

.c-topbar-social {
    div {
        margin-left: 10px;
        display: inline-block;
        &:first-child {
            margin-left: 0;
        }
    }
    .youtube-icon {
        width: 20px;
        height: 20px;

        @include bp-medium() {
            width: 24px;
            height: 24px;
        }
    }
    .vimeo-icon {
        width: 18px;
        height: 18px;

        @include bp-medium() {
            width: 22px;
            height: 22px;
        }
    }
    .instagram-icon {
        width: 18px;
        height: 18px;

        @include bp-medium() {
            width: 22px;
            height: 22px;
        }
    }
    .facebook-icon {
        width: 9px;
        height: 18px;

        @include bp-medium() {
            width: 13px;
            height: 22px;
        }
    }
}
