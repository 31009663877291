/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding: 80px 20px 40px 20px;
  background: {
      image: url('../assets/images/noise-bg.jpg');
      repeat: repeat;
  }
  p {
    margin-bottom: 0;
  }
  a {
    color: $color-white;
  }

  .o-wrapper {
    display: flex;
    flex-direction: column;

    @include bp-medium() {
      flex-direction: row;
    }
  }
}

.c-footer-logo {
  text-align: center;
  @include bp-medium() {
    width: 20%;
    text-align: left;
  }

  img {
    width: 150px;
  }
}

.c-footer-contact {
  margin-top: 60px;

  @include bp-medium() {
    display: flex;
    justify-content: space-between;

    width: 80%;
    margin-top: 0;
  }
}

.c-footer-links-wrapper {
  margin-top: 60px;
}

.c-footer-contact-wrap {
  display: block;
  margin-top: 40px;
  @include clearfix();
  @include bp-medium() {
    margin-top: 0;
  }
  &:first-child {
    margin-top: 0;
  }
}

.c-footer-contact-icon {
  text-align: center;
  @include bp-small() {
    width: 80px;
    float: left;
    top: 0;
  }

  img {
    width: 40px;
  }
  .icon--phone {
    width: 32px;
  }
}

.c-footer-contact-text {
  text-align: center;
  margin-top: 20px;
  color: $color-white;
  @include bp-small() {
    float: left;
    text-transform: uppercase;
    text-align: left;
    margin-top: 5px;
  }

  .c-footer-contact-text-title {
    margin: 0;
    font-size: 1.5rem;

    @include bp-medium() {
      font-size: 1rem;
    }
  }

  .c-footer-contact-text-desc {
    font-size: 1rem;
    font-weight: 100;
    
    @include bp-medium() {
      font-size: .8rem;
    }
  }
}

.c-footer-links {
  text-transform: uppercase;
  color: $color-white;
  margin-top: 50px;
  text-align: center;

  @include bp-small() {
    text-align: left;
  }
  @include bp-medium() {
    width: 35%;
    margin-top: 0;
  }
}

.c-footer-links--title {
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
}

.c-footer-links--sitelinks a{
    color: $color-white;
    font-weight: 700;
}

.c-footer-links--terms {
  margin-top: 40px;
  a {
    color: $color-white;
    font-weight: 700;
  }
}
