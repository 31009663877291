.c-contact-form {
    padding: 40px 20px;
}

.c-contact-form-title {
    text-align: center;
}

.c-contact-form-content {
    max-width: 900px;
    margin: 60px auto 0;

    p {
        position: relative;
    }

    input {
        border: 0;
        border-bottom: 1px solid $color-red;
        outline: 0;
        padding-bottom: 8px;
        width: 100%;
    }

    textarea {
        border: 1px solid $color-red;
        outline: 0;
        width: 100%;
    }

    label {
        position: absolute;
        bottom: 5px;
        left: 0;
        z-index: 2;
        text-transform: uppercase;
        color: $color-red;
    }

    .c-contact-form-ta-label {
        top: -8px;
        bottom: auto;
    }

    .wpcf7-submit {
        border: 0;
        background: $color-black;
        color: $color-white;
        padding: 10px 15px;;
        text-transform: uppercase;
        font-size: 0.9em;
        letter-spacing: 1.3px;
        display: inline-block;
        width: auto;
        margin: 0 auto;
        margin-top: 30px;
    }

    .wpcf7-form {
        p:nth-child(5) {
            margin-top: 40px;
        }

        p:nth-child(6) {
            text-align: center;
        }
    }
}

.wpcf7 {
    p {
        text-align: left!important;
        color: $color-red;
    }
}

.wpcf7-not-valid-tip {
    position: absolute;
    left: 0;
    bottom: -25px;
}

.wpcf7-validation-errors {
    color: $color-red;
}

label {
    transition: opacity .3s ease-in-out;
}

.wpcf7-acceptance {
    display: block;
    margin-top: 60px;

    input {
        width: auto;
        margin-right: 10px;
    }

    a {
        font-weight: 700;
        color: $color-red;
    }
}

.g-recaptcha {
    display: inline-block;
    margin-top: 16px;
    margin-bottom: 20px;
}