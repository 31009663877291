@charset "UTF-8";
/*
  Project: 2track
  Author: Daniel
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&subset=latin-ext");
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 100;
  margin-bottom: 10px;
  letter-spacing: 1.5px;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

.special-hr {
  margin: 0;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000001;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  font-weight: 300;
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  letter-spacing: 0.5px;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
  image-rendering: -webkit-optimize-contrast;
  /* CSS3 Proposed                  */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #000001;
  text-decoration: none;
}

a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.no-dots {
  margin: 0;
}

.no-dots li {
  list-style-type: none;
  margin: 0;
}

strong {
  font-weight: 500;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-article {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.o-hamburger {
  display: block;
  position: absolute;
  cursor: pointer;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 35px;
}

@media (min-width: 768px) {
  .o-hamburger {
    width: 45px;
  }
}

.o-hamburger span {
  width: 35px;
  display: block;
  height: 3px;
  background: #fff;
  margin-top: 5px;
  transform-origin: 4px 0;
  position: relative;
}

@media (min-width: 768px) {
  .o-hamburger span {
    width: 45px;
  }
}

.o-hamburger span:nth-child(1) {
  margin-top: 0;
  transform-origin: 0% 0%;
}

.o-hamburger span:nth-child(3) {
  transform-origin: 0% 100%;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.c-embed--video {
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.c-embed--video iframe,
.c-embed--video object,
.c-embed--video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  width: 100%;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border: 1px solid;
  border-radius: 15px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #000001;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #999;
}

.c-btn--gallery {
  font-size: 1rem;
  border-radius: 12px;
  padding: 6px 18px;
  text-transform: uppercase;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.15rem 1.3rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* Border color variants
   ========================================================================== */
.c-btn--white {
  border-color: #fff;
  color: #fff;
  transition: all 0.3s ease-in;
}

.c-btn--white:hover {
  background: #fff;
  color: #000001;
}

.c-btn--red {
  color: #ed2940;
  border-color: #ed2940;
}

.c-btn--red a {
  color: #ed2940;
}

.c-btn--red:hover {
  background: #ed2940;
  color: #fff;
  border-color: #ed2940;
}

.c-btn--red:hover a {
  color: #fff;
}

.c-btn--red-inverse {
  background: #ed2940;
  color: #fff;
  border-color: #ed2940;
}

.c-btn--red-inverse a {
  color: #fff;
}

.c-btn--red-inverse:hover {
  background: transparent;
  color: #ed2940;
  border-color: #ed2940;
}

.c-btn--red-inverse:hover a {
  color: #ed2940;
}

.c-btn-go-back-wrap {
  width: 100%;
  text-align: center;
}

.c-btn-go-to-website {
  text-align: center;
  display: inline-block;
}

.big-red-arrow {
  font-size: 24px;
  font-weight: 700;
  color: #ed2940;
  display: inline-block;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-contact-form {
  padding: 40px 20px;
}

.c-contact-form-title {
  text-align: center;
}

.c-contact-form-content {
  max-width: 900px;
  margin: 60px auto 0;
}

.c-contact-form-content p {
  position: relative;
}

.c-contact-form-content input {
  border: 0;
  border-bottom: 1px solid #ed2940;
  outline: 0;
  padding-bottom: 8px;
  width: 100%;
}

.c-contact-form-content textarea {
  border: 1px solid #ed2940;
  outline: 0;
  width: 100%;
}

.c-contact-form-content label {
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 2;
  text-transform: uppercase;
  color: #ed2940;
}

.c-contact-form-content .c-contact-form-ta-label {
  top: -8px;
  bottom: auto;
}

.c-contact-form-content .wpcf7-submit {
  border: 0;
  background: #000001;
  color: #fff;
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 1.3px;
  display: inline-block;
  width: auto;
  margin: 0 auto;
  margin-top: 30px;
}

.c-contact-form-content .wpcf7-form p:nth-child(5) {
  margin-top: 40px;
}

.c-contact-form-content .wpcf7-form p:nth-child(6) {
  text-align: center;
}

.wpcf7 p {
  text-align: left !important;
  color: #ed2940;
}

.wpcf7-not-valid-tip {
  position: absolute;
  left: 0;
  bottom: -25px;
}

.wpcf7-validation-errors {
  color: #ed2940;
}

label {
  transition: opacity .3s ease-in-out;
}

.wpcf7-acceptance {
  display: block;
  margin-top: 60px;
}

.wpcf7-acceptance input {
  width: auto;
  margin-right: 10px;
}

.wpcf7-acceptance a {
  font-weight: 700;
  color: #ed2940;
}

.g-recaptcha {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 20px;
}

.c-contact-small {
  padding: 60px 0;
  text-align: center;
}

.c-contact-small-person {
  margin-top: 35px;
}

.cookies {
  position: fixed;
  background: #eee;
  max-width: 100%;
  bottom: 0;
  display: none;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: 400;
  z-index: 10000;
  padding: 10px 20px;
  text-align: center;
}

.accept-cookie {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: inline;
}

.c-copyright {
  margin: 20px auto;
  font-size: 0.8rem;
}

.c-copyright:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-copyright-left {
  text-align: center;
}

@media (min-width: 480px) {
  .c-copyright-left {
    text-align: left;
    float: left;
    margin: 0;
    padding-left: 10px;
  }
}

.c-copyright-right {
  text-align: center;
}

@media (min-width: 480px) {
  .c-copyright-right {
    float: right;
    text-align: right;
    margin: 0;
    padding-right: 10px;
  }
}

.demo-content {
  padding: 100px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.demo-content video {
  width: 100%;
}

.demo-media {
  padding: 5px 0;
  max-width: 500px;
}

.demo-media video {
  max-width: 400px;
}

.demo-media-title {
  font-weight: 700;
}

/* ==========================================================================
   #Featured music
   ========================================================================== */
.c-feature-music {
  padding: 40px 0;
  text-align: center;
  background-image: url("../assets/images/noise-bg.jpg");
  background-repeat: repeat;
}

.c-feature-music-title {
  color: #fff;
}

.c-feature-music-subtitle {
  font-weight: 100;
  font-size: 1.7rem;
  color: #fff;
  text-align: left;
  margin: 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.c-feature-music-description {
  color: #fff;
}

.c-feature-music-grid {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
}

.c-feature-music-grid-item {
  padding: 20px;
  width: 100%;
}

.c-feature-music-grid-item img {
  width: 100%;
}

@media (min-width: 768px) {
  .c-feature-music-grid-item {
    width: 33.3%;
  }
}

.c-feature-music-grid-item__image {
  overflow: hidden;
}

.c-feature-music-grid-item__image img {
  transition: transform 0.3s ease-in-out;
}

.c-feature-music-grid-item__image:hover img {
  transform: scale(1.1);
}

.c-feature-music-grid-item--box {
  margin-top: 15px;
}

.c-feature-music-grid-item--artist {
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  margin: 0;
}

.c-feature-music-grid-item--album {
  font-size: 1rem;
  color: #fff;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  padding: 80px 20px 40px 20px;
  background-image: url("../assets/images/noise-bg.jpg");
  background-repeat: repeat;
}

.c-footer p {
  margin-bottom: 0;
}

.c-footer a {
  color: #fff;
}

.c-footer .o-wrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-footer .o-wrapper {
    flex-direction: row;
  }
}

.c-footer-logo {
  text-align: center;
}

@media (min-width: 768px) {
  .c-footer-logo {
    width: 20%;
    text-align: left;
  }
}

.c-footer-logo img {
  width: 150px;
}

.c-footer-contact {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .c-footer-contact {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 0;
  }
}

.c-footer-links-wrapper {
  margin-top: 60px;
}

.c-footer-contact-wrap {
  display: block;
  margin-top: 40px;
}

.c-footer-contact-wrap:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-footer-contact-wrap {
    margin-top: 0;
  }
}

.c-footer-contact-wrap:first-child {
  margin-top: 0;
}

.c-footer-contact-icon {
  text-align: center;
}

@media (min-width: 480px) {
  .c-footer-contact-icon {
    width: 80px;
    float: left;
    top: 0;
  }
}

.c-footer-contact-icon img {
  width: 40px;
}

.c-footer-contact-icon .icon--phone {
  width: 32px;
}

.c-footer-contact-text {
  text-align: center;
  margin-top: 20px;
  color: #fff;
}

@media (min-width: 480px) {
  .c-footer-contact-text {
    float: left;
    text-transform: uppercase;
    text-align: left;
    margin-top: 5px;
  }
}

.c-footer-contact-text .c-footer-contact-text-title {
  margin: 0;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .c-footer-contact-text .c-footer-contact-text-title {
    font-size: 1rem;
  }
}

.c-footer-contact-text .c-footer-contact-text-desc {
  font-size: 1rem;
  font-weight: 100;
}

@media (min-width: 768px) {
  .c-footer-contact-text .c-footer-contact-text-desc {
    font-size: .8rem;
  }
}

.c-footer-links {
  text-transform: uppercase;
  color: #fff;
  margin-top: 50px;
  text-align: center;
}

@media (min-width: 480px) {
  .c-footer-links {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .c-footer-links {
    width: 35%;
    margin-top: 0;
  }
}

.c-footer-links--title {
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
}

.c-footer-links--sitelinks a {
  color: #fff;
  font-weight: 700;
}

.c-footer-links--terms {
  margin-top: 40px;
}

.c-footer-links--terms a {
  color: #fff;
  font-weight: 700;
}

.c-gallery-section {
  background: #e2e2e2;
}

.c-gallery-section__title {
  padding-top: 60px;
  margin-bottom: 30px;
  text-align: center;
}

.c-gallery-section__loop {
  padding: 0 15px 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.c-gallery-section__loop + .alm-btn-wrap {
  text-align: center;
  padding-bottom: 30px !important;
}

.c-gallery-section__loop + .alm-btn-wrap .alm-load-more-btn {
  margin-top: 0;
}

.c-gallery-section__item--image, .c-gallery-section__item--video {
  overflow: hidden;
}

.c-gallery-section__item--image img, .c-gallery-section__item--video img {
  transition: transform 0.3s ease-in-out;
}

.c-gallery-section__item--image:hover img, .c-gallery-section__item--video:hover img {
  transform: scale(1.1);
}

.c-gallery-section__item--video {
  position: relative;
}

.c-gallery-section__item--video:after {
  content: "";
  transition: all 0.3s ease-in-out;
}

.c-gallery-section__item--video:hover:after {
  content: "";
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}

.c-gallery-section__item--video:hover:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48Zz4KCTxnIGlkPSJwbGF5LWNpcmNsZS1vdXRsaW5lIj4KCQk8cGF0aCBkPSJNMjA0LDM2OS43NUwzNTcsMjU1TDIwNCwxNDAuMjVWMzY5Ljc1eiBNMjU1LDBDMTE0Ljc1LDAsMCwxMTQuNzUsMCwyNTVzMTE0Ljc1LDI1NSwyNTUsMjU1czI1NS0xMTQuNzUsMjU1LTI1NSAgICBTMzk1LjI1LDAsMjU1LDB6IE0yNTUsNDU5Yy0xMTIuMiwwLTIwNC05MS44LTIwNC0yMDRTMTQyLjgsNTEsMjU1LDUxczIwNCw5MS44LDIwNCwyMDRTMzY3LjIsNDU5LDI1NSw0NTl6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: cover;
  width: 60px;
  height: 60px;
  display: inline-block;
  z-index: 10;
}

.c-gallery-slide {
  height: 100vh;
  width: 100%;
  transition: all .8s ease-out;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.c-gallery-slide-bottom-content {
  position: absolute;
  bottom: 30px;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  right: 0;
  left: 0;
}

@media (min-width: 768px) {
  .c-gallery-slide-bottom-content--o-nas {
    top: 40px;
    bottom: auto;
  }
}

.c-gallery-slide-bottom-content .c-gallery-slide-bottom-content-title {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.c-gallery-slide-top-content {
  padding-top: 40px;
  text-align: center;
  color: #fff;
}

.c-gallery-slide-top-content .c-gallery-slide-top-content-logo {
  width: 180px;
  margin: 0 auto;
}

.c-gallery-slide-top-content .c-gallery-slide-top-content-title {
  margin: 0;
  margin-top: 30px;
  font-size: 2.3rem;
  text-transform: uppercase;
}

.c-gallery-slide-top-content .c-gallery-slide-top-content-desc {
  font-size: .9rem;
}

.c-header-slider {
  position: relative;
  height: calc(60vh + 60px);
  padding-top: 60px;
}

@media (min-width: 768px) {
  .c-header-slider {
    height: calc(60vh + 80px);
    padding-top: 80px;
  }
}

.c-header-slider__item {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.c-header-slider__wrapper > div > div {
  height: 60vh;
}

.c-header-slider__box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 1000px;
  padding: 0 15px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header-slider__title {
  color: #fff;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.c-header-slider__title p {
  margin: 0;
}

.c-header-slider__arrow {
  color: #ed2940;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #565656;
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 700;
  opacity: 0.6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s opacity ease-in;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.c-header-slider__arrow:hover {
  opacity: 1;
}

.c-header-slider__arrow--left {
  margin-top: 30px;
  left: 10px;
}

@media (min-width: 768px) {
  .c-header-slider__arrow--left {
    margin-top: 40px;
  }
}

.c-header-slider__arrow--right {
  margin-top: 30px;
  right: 10px;
}

@media (min-width: 768px) {
  .c-header-slider__arrow--right {
    margin-top: 40px;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  display: block;
  height: calc(100vh - 50px);
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .c-header {
    height: calc(100vh - 80px);
  }
}

.c-header__wrapper {
  padding-top: 50px;
}

@media (min-width: 768px) {
  .c-header__wrapper {
    padding-top: 80px;
  }
}

.c-header-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.c-header-center .c-header-logo {
  padding: 0;
  margin-bottom: 30px;
}

.c-header-post-type {
  font-size: 2rem;
  color: #fff;
  font-weight: 100;
}

.c-header-title {
  font-size: 2rem;
  color: #fff;
}

.c-header-scroll {
  position: absolute;
  bottom: 25px;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header-scroll img {
  width: 30px;
  display: block;
  margin: 0 auto;
}

.c-header-scroll--text {
  font-size: 0.8em;
  color: #fff;
}

.c-header-scroll--action {
  text-decoration: none;
  display: inline-block;
}

.c-header-mini-title {
  position: absolute;
  z-index: 2;
  bottom: 95px;
  text-align: center;
  width: 100%;
}

.c-header-mini-title h1 {
  font-weight: 100;
  color: #fff;
  font-size: 30px;
}

.scroll-down {
  animation: moveMouse 2s infinite;
}

.scroll-down:hover {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

@keyframes moveMouse {
  0% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: block;
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.95);
  padding-top: 5em;
  padding-left: 1.5em;
  transition: top .3s ease-in-out;
  height: 100vh;
  overflow: hidden;
}

.c-main-nav__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.c-main-nav-items {
  margin-top: 50px;
}

.c-navbar-logo {
  display: block;
  margin: 80px auto 20px;
  width: 80px;
}

@media (min-width: 768px) {
  .c-navbar-logo {
    width: 90px;
  }
}

.c-navbar-logo__icon {
  width: 80px;
}

@media (min-width: 768px) {
  .c-navbar-logo__icon {
    width: 90px;
  }
}

.c-main-nav-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.c-main-nav-close span {
  display: inline-block;
  position: absolute;
  width: 3px;
  height: 100%;
  transform: rotate(45deg);
  background: #ed2940;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c-main-nav-close span:first-child {
  transform: rotate(-45deg);
}

.c-main-nav-show {
  top: 0;
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;
}

@media (min-width: 768px) {
  .c-main-nav__item {
    font-size: 1.2rem;
    line-height: 2.2;
  }
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  display: inline;
  text-decoration: none;
  color: #999;
}

.c-main-nav__link:hover {
  color: #ed2940;
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: #fff;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #fff;
}

.c-main-nav__social {
  position: absolute;
  display: flex !important;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;
}

@media (min-width: 480px) {
  .c-main-nav__social {
    display: none !important;
  }
}

.c-news {
  padding: 60px 20px;
}

.c-news-title {
  text-align: center;
}

.c-news-grid {
  margin-top: 40px;
}

.c-news-grid:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-news-calendar {
  width: 100px;
  float: left;
  position: relative;
}

.c-news-icon-calendar {
  width: 70px;
  padding-left: 10px;
}

.c-news-grid-item {
  border-bottom: 1px solid #ed2940;
  padding-bottom: 30px;
  margin-top: 30px;
}

.c-news-grid-item:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-news-content {
  width: calc(100% - 100px);
  float: left;
}

.c-news-content:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-news-content .c-btn {
  float: right;
  margin-top: 20px;
}

.c-news-calendar-date {
  position: absolute;
  top: 25px;
  left: 18px;
  color: #ed2940;
}

.c-news-content-title {
  display: block;
  font-weight: 700;
  color: #ed2940;
  text-transform: uppercase;
}

.c-partner-sites {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .c-partner-sites {
    padding: 0 100px;
  }
}

.c-partner-sites + .alm-btn-wrap {
  text-align: center;
}

.c-partner-sites + .alm-btn-wrap .u-show-more--text {
  color: #000001 !important;
}

.c-partner-sites-item-wrap:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-partner-sites-item {
  margin-top: 80px;
}

.c-partner-sites-item-icon {
  width: 60px;
  float: left;
}

.c-partner-sites-item-title {
  width: auto;
  float: left;
  text-transform: uppercase;
  color: #ed2940;
  font-weight: 700;
  padding-left: 13px;
  padding-top: 5px;
}

.c-partner-sites-item-text {
  padding-top: 10px;
}

.c-partner-sites-item-text .c-btn {
  margin-top: 10px;
}

.password-section {
  padding: 100px 20px;
  text-align: center;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
  max-width: 768px;
  margin: 0 auto;
  padding: 50px 0;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-section-text {
  margin-bottom: 80px;
}

.c-section-text-title, .c-section-text-desc {
  text-align: center;
}

.c-section-text-title {
  padding-top: 60px;
  margin-bottom: 30px;
}

.c-section-text-desc {
  padding-bottom: 40px;
}

.logo-red--about {
  width: 200px;
  margin: 60px auto;
}

.c-section-text__btn {
  text-align: center;
}

.c-section-text__btn .c-btn {
  margin-top: 20px;
}

.c-services {
  padding: 60px 20px 80px 20px;
}

.c-services-title {
  text-align: center;
}

.c-services-grid:after,
.c-services-grid-item-wrap:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-services-item {
  margin-top: 80px;
}

.c-services-grid-item {
  margin-top: 80px;
}

@media (min-width: 768px) {
  .c-services-grid-item {
    width: 50%;
    padding-right: 20%;
    float: left;
  }
}

.c-services-grid-item-icon {
  width: 60px;
  float: left;
}

.c-services-grid-item-title {
  width: auto;
  float: left;
  text-transform: uppercase;
  color: #ed2940;
  font-weight: 700;
  padding-left: 13px;
  padding-top: 5px;
}

.c-services-grid-item-text {
  padding-top: 10px;
}

.c-services-grid-item-text .c-services-grid-item-text-desc {
  font-size: 0.9rem;
}

.c-services-grid-item-text .c-btn {
  margin-top: 10px;
  float: right;
}

.c-album__section {
  margin: 0 15px;
}

.c-album__section-wrapper {
  padding: 90px 0;
}

@media (min-width: 480px) {
  .c-album__section-wrapper {
    display: flex;
  }
}

@media (min-width: 480px) {
  .c-album__cover {
    width: 40%;
    padding-right: 20px;
  }
}

.c-album__aside {
  margin-top: 40px;
}

@media (min-width: 480px) {
  .c-album__aside {
    margin: 0;
    width: 60%;
    padding: 15px 30px;
  }
}

.c-album__title {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.c-album__title p {
  margin: 0;
}

.c-album__icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.c-album__icons-item {
  width: 50px;
  margin: 0 12px;
}

.c-album__icons-item--border {
  position: relative;
  height: 50px;
  border: 1px solid #ed2940;
  border-radius: 50%;
}

.c-album__icons-item--border img {
  width: 30px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.c-album__see-also__title {
  font-size: 1.5rem;
  padding-bottom: 15px;
  border-bottom: 1px solid #000001;
}

.c-album__see-also__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 30px;
  padding: 0;
  padding-top: 30px;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .c-album__see-also__wrapper {
    flex-wrap: nowrap;
    padding: 30px 15px 0;
  }
}

.c-album__see-also__item {
  position: relative;
  height: 0;
  width: calc(50% - 20px);
  margin: 10px;
  height: auto;
}

@media (min-width: 768px) {
  .c-album__see-also__item {
    width: 20%;
  }
}

.c-album__see-also__item .c-album__see-also__item-1x1 {
  padding-bottom: 100%;
}

.c-album__see-also__item img {
  position: absolute;
}

.c-social-media {
  padding: 60px 0;
}

.c-social-media-title {
  text-align: center;
  color: #000001;
}

.c-social-media-desc {
  text-align: center;
  color: #ed2940;
}

.c-social-media-grid {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.c-social-media-item {
  display: inline-block;
  margin-top: 20px;
  margin-left: 40px;
}

.c-social-media-item:first-child {
  margin-left: 0;
}

.c-social-media-item img {
  width: 60px;
}

/* ==========================================================================
   #TOPBAR
   ========================================================================== */
.c-topbar {
  height: 60px;
  background: #343434;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.c-topbar:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-topbar {
    height: 80px;
  }
}

.c-topbar-nav {
  background: #ed2940;
  width: 80px;
  height: 100%;
  position: relative;
  display: flex;
}

@media (min-width: 768px) {
  .c-topbar-nav {
    width: 95px;
  }
}

.c-topbar-logo {
  display: flex;
  position: absolute;
  height: 100%;
  width: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .c-topbar-logo {
    width: 75px;
  }
}

.c-topbar-lang {
  color: #fff;
  margin-left: 20px;
  font-size: 0.9em;
  position: absolute;
  right: 20px;
}

@media (min-width: 480px) {
  .c-topbar-lang {
    position: relative;
    margin-left: 20px;
    right: auto;
  }
}

.c-topbar-lang a {
  color: #fff;
}

.c-topbar-lang .left-arrow-red {
  width: 12px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 2px;
}

.c-topbar-lang span {
  font-weight: 300;
  display: none;
}

@media (min-width: 768px) {
  .c-topbar-lang .left-arrow-red, .c-topbar-lang span {
    display: inline-block;
  }
}

.c-topbar__right-part {
  display: none;
}

@media (min-width: 480px) {
  .c-topbar__right-part {
    display: flex;
    margin-left: auto;
    margin-right: 20px;
  }
}

.c-topbar-family-sites {
  position: relative;
  margin-right: 30px;
}

.c-topbar-family-sites:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 115%;
  top: 0;
  background: #fff;
  right: -15px;
}

.c-topbar-family-sites .shop-icon {
  width: 20px;
}

@media (min-width: 768px) {
  .c-topbar-family-sites .shop-icon {
    width: 24px;
  }
}

.c-topbar-social div {
  margin-left: 10px;
  display: inline-block;
}

.c-topbar-social div:first-child {
  margin-left: 0;
}

.c-topbar-social .youtube-icon {
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .c-topbar-social .youtube-icon {
    width: 24px;
    height: 24px;
  }
}

.c-topbar-social .vimeo-icon {
  width: 18px;
  height: 18px;
}

@media (min-width: 768px) {
  .c-topbar-social .vimeo-icon {
    width: 22px;
    height: 22px;
  }
}

.c-topbar-social .instagram-icon {
  width: 18px;
  height: 18px;
}

@media (min-width: 768px) {
  .c-topbar-social .instagram-icon {
    width: 22px;
    height: 22px;
  }
}

.c-topbar-social .facebook-icon {
  width: 9px;
  height: 18px;
}

@media (min-width: 768px) {
  .c-topbar-social .facebook-icon {
    width: 13px;
    height: 22px;
  }
}

.c-video {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-video-source {
  position: absolute;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  left: 0;
}

.c-video-overlay, .c-video-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.c-video-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.c-video-content-title {
  text-align: center;
  color: #fff;
  margin-top: 30px;
}

.c-video-content-desc {
  text-align: center;
  color: #fff;
}

.c-video-content-play {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 100;
  letter-spacing: 2px;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-video-content-play a {
  color: #fff;
  border: 1px solid #fff;
  padding: 15px 25px;
  transition: all .3s ease-in-out;
  border-radius: 40px;
}

.c-video-content-play a:hover {
  background: #fff;
  color: #000001;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-margin-center {
  margin: 0 auto;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-fill {
  background: #ccc;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.alm-load-more-btn {
  display: inline-block;
  margin-top: 50px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.alm-load-more-btn .u-show-more--plus {
  font-size: 40px;
  font-weight: 900;
  color: #ed2940;
  line-height: 1.2;
}

.alm-load-more-btn .u-show-more--text {
  color: #fff;
  font-weight: 100;
}

.alm-btn-wrap .done {
  display: none;
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */
.u-margin {
  margin: 1em;
}

.u-margin-top {
  margin-top: 1em;
}

.u-margin-top--15 {
  margin-top: 1.5em;
}

.u-margin-right {
  margin-right: 1em;
}

.u-margin-bottom {
  margin-bottom: 1em;
}

.u-margin-left {
  margin-left: 1em;
}

.u-padding {
  padding: 1em;
}

.u-padding-top {
  padding-top: 1em;
}

.u-padding-right {
  padding-right: 1em;
}

.u-padding-bottom {
  padding-bottom: 1em;
}

.u-padding-left {
  padding-left: 1em;
}

.mt30 {
  margin-top: 30px;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-bottom-bar {
  display: none;
}
