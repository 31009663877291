.c-social-media {
    padding: 60px 0;
}

.c-social-media-title {
    text-align: center;
    color: $color-black;
}

.c-social-media-desc {
    text-align: center;
    color: $color-red;
}

.c-social-media-grid {
    display: block;
    text-align: center;
    margin-top: 20px;
}

.c-social-media-item {
    display: inline-block;
    margin-top: 20px;
    margin-left: 40px;

    &:first-child {
        margin-left: 0;
    }
    img {
        width: 60px;
    }
}
