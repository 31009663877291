.c-section-text {
    margin-bottom: 80px;
}

.c-section-text-title, .c-section-text-desc {
    text-align: center;
}

.c-section-text-title {
    padding-top: 60px;
    margin-bottom: 30px;
}

.c-section-text-desc {
    padding-bottom: 40px;
}

.logo-red--about {
    width: 200px;
    margin: 60px auto;
}

.c-section-text__btn {
    text-align: center;

    .c-btn {
        margin-top: 20px;
    }
}
