.o-hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 35px;

    @include bp-medium() {
        width: 45px;
    }

    span {
        width: 35px;
        @include bp-medium() {
            width: 45px;
        }
        display: block;
        height: 3px;
        background: $color-white;
        margin-top: 5px;
        transform-origin: 4px 0;
        position: relative;
        &:nth-child(1) {
            margin-top: 0;
            transform-origin: 0% 0%;
        }
        &:nth-child(3) {
            transform-origin: 0% 100%;
        }
    }
}
