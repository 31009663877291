.c-copyright {
    margin: 20px auto;
    font-size: 0.8rem;
    @include clearfix();
}

.c-copyright-left {
    text-align: center;
    @include bp-small() {
        text-align: left;
        float: left;
        margin: 0;
        padding-left: 10px;
    }
}

.c-copyright-right {
    text-align: center;
    @include bp-small() {
        float: right;
        text-align: right;
        margin: 0;
        padding-right: 10px;
    }
}