.c-album__section {
  margin: 0 15px;
}
.c-album__section-wrapper {


  padding: 90px 0;

  @include bp-small() {
    display: flex;
  }
}

.c-album__cover {
  @include bp-small() {
    width: 40%;
    padding-right: 20px;
  }
}

.c-album__aside {
  margin-top: 40px;

  @include bp-small() {
    margin: 0;
    width: 60%;
    padding: 15px 30px;
  }
}

.c-album__title {
  font-size: 1.5rem;
  margin-bottom: 40px;

  p {
    margin: 0;
  }
}

.c-album__icons {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.c-album__icons-item {
  width: 50px;
  margin: 0 12px;

  &--border {
    position: relative;
    height: 50px;

    border: 1px solid $color-red;
    border-radius: 50%;

    img {
      width: 30px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.c-album__see-also__title {
  font-size: 1.5rem;
  padding-bottom: 15px;

  border-bottom: 1px solid $color-black;
}

.c-album__see-also__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 30px;
  padding: 0;
  padding-top: 30px;

  justify-content: space-between;

  @include bp-medium() {
    flex-wrap: nowrap;
    padding: 30px 15px 0;
  }
}

.c-album__see-also__item {
  position: relative;
  height: 0;
  width: calc(50% - 20px);
  margin: 10px;
  height: auto;

  @include bp-medium() {
    width: 20%;
  }

  .c-album__see-also__item-1x1 {
    padding-bottom: 100%;
  }

  img {
    position: absolute;
  }
}
