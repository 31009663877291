/* ==========================================================================
   #Featured music
   ========================================================================== */

.c-feature-music {
    padding: 40px 0;
    text-align: center;
    background: {
        image: url('../assets/images/noise-bg.jpg');
        repeat: repeat;
    }
}

.c-feature-music-title {
    color: $color-white;
}

.c-feature-music-subtitle {
    font-weight: 100;
    font-size: 1.7rem;
    color: $color-white;
    text-align: left;
    margin: 0 20px;
    padding-bottom: 10px;

    border-bottom: 1px solid $color-white;
}

.c-feature-music-description {
    color: $color-white;
}

.c-feature-music-grid {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
}

.c-feature-music-grid-item {
    padding: 20px;
    width: 100%;

    img {
        width: 100%;
    }

    @include bp-medium {
        width: 33.3%;
    }
}

.c-feature-music-grid-item__image {
    overflow: hidden;

    img {
        transition: transform 0.3s ease-in-out;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.c-feature-music-grid-item--box {
    margin-top: 15px;
}

.c-feature-music-grid-item--artist {
    font-weight: 700;
    font-size: 1rem;
    color: $color-white;
    margin: 0;
}

.c-feature-music-grid-item--album {
    font-size: 1rem;
    color: $color-white;
}
