.c-services {
    padding: 60px 20px 80px 20px;
}

.c-services-title {
    text-align: center;
}

.c-services-grid,
.c-services-grid-item-wrap {
    @include clearfix();
}

.c-services-grid {
}

.c-services-item {
    margin-top: 80px;
}

.c-services-grid-item {
    margin-top: 80px;
    @include bp-medium {
        width: 50%;
        padding-right: 20%;
        float: left;
    }
}

.c-services-grid-item-icon {
    width: 60px;
    float: left;
}

.c-services-grid-item-title {
    width: auto;
    float: left;
    text-transform: uppercase;
    color: $color-red;
    font-weight: 700;
    padding-left: 13px;
    padding-top: 5px;
}

.c-services-grid-item-text {
    padding-top: 10px;

    .c-services-grid-item-text-desc {
        font-size: 0.9rem;
    }
    .c-btn {
        margin-top: 10px;
        float: right;
    }
}
