.c-gallery-slide {
    height: 100vh;
    width: 100%;
    transition: all .8s ease-out;
    background: {
        repeat: no-repeat;
        size: cover;
    }
    position: relative;
}

.c-gallery-slide-bottom-content {
    position: absolute;
    bottom: 30px;
    font-size: 1.2rem;
    color: $color-white;
    text-align: center;
    margin: 0 auto;
    right: 0;
    left: 0;

    &--o-nas {
        @include bp-medium() {
            top: 40px;
            bottom: auto;
        }
    }

    .c-gallery-slide-bottom-content-title {
        font-size: 1.5rem;
        text-transform: uppercase;
    }
}

.c-gallery-slide-top-content {
    padding-top: 40px;
    text-align: center;
    color: $color-white;

    .c-gallery-slide-top-content-logo {
        width: 180px;
        margin: 0 auto;
    }
    .c-gallery-slide-top-content-title {
        margin: 0;
        margin-top: 30px;
        font-size: 2.3rem;
        text-transform: uppercase;
    }
    .c-gallery-slide-top-content-desc {
        font-size: .9rem;
    }
}
