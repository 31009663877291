/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: block;
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(#000000, 0.95);
  padding-top: 5em;
  padding-left: 1.5em;
  transition: top .3s ease-in-out;
  height: 100vh;
  overflow: hidden;
}

.c-main-nav__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.c-main-nav-items {
  margin-top: 50px;
}

.c-navbar-logo {
  display: block;

  margin: 80px auto 20px;
  width: 80px;

  @include bp-medium() {
    width: 90px;
  }
}

.c-navbar-logo__icon {
  width: 80px;

  @include bp-medium() {
    width: 90px;
  }
}

.c-main-nav-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  span {
    display: inline-block;
    position: absolute;
    width: 3px;
    height: 100%;
    transform: rotate(45deg);
    background: $color-red;
    left: 0;
    right: 0;
    margin: 0 auto;

    &:first-child {
      transform: rotate(-45deg);
    }
  }
}

.c-main-nav-show {
  top: 0;
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;

  @include bp-medium() {
    font-size: 1.2rem;
    line-height: 2.2;
  }

  &:hover .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__link {
  display: inline;
  text-decoration: none;
  color: $color-light-grey;

  &:hover {
    color: $color-red;
  }
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: $color-white;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-white;
}

.c-main-nav__social {
  position: absolute;
  display: flex!important;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;

  @include bp-small() {
    display: none!important;
  }
}