.c-gallery-section {
  background: $color-grey-gallery;
}

.c-gallery-section__title {
  padding-top: 60px;
  margin-bottom: 30px;
  text-align: center;
}

.c-gallery-section__loop {
  padding: 0 15px 40px;
  max-width: 1200px;
  margin: 0 auto;

  + .alm-btn-wrap {
    text-align: center;
    padding-bottom: 30px!important;

    .alm-load-more-btn {
      margin-top: 0;
    }
  }
}

.c-gallery-section__item {
  &--image,
  &--video {
    overflow: hidden;

    img {
        transition: transform 0.3s ease-in-out;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
  }

  &--video {
    position: relative;
    &:after {
      content: "";
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &:after {
        content: "";
        transition: all 0.3s ease-in-out;
        background-color: rgba(#000, 0.6);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        margin: 0 auto;
        left: 0;
        right: 0;

        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48Zz4KCTxnIGlkPSJwbGF5LWNpcmNsZS1vdXRsaW5lIj4KCQk8cGF0aCBkPSJNMjA0LDM2OS43NUwzNTcsMjU1TDIwNCwxNDAuMjVWMzY5Ljc1eiBNMjU1LDBDMTE0Ljc1LDAsMCwxMTQuNzUsMCwyNTVzMTE0Ljc1LDI1NSwyNTUsMjU1czI1NS0xMTQuNzUsMjU1LTI1NSAgICBTMzk1LjI1LDAsMjU1LDB6IE0yNTUsNDU5Yy0xMTIuMiwwLTIwNC05MS44LTIwNC0yMDRTMTQyLjgsNTEsMjU1LDUxczIwNCw5MS44LDIwNCwyMDRTMzY3LjIsNDU5LDI1NSw0NTl6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==);
        background-repeat: no-repeat;
        background-size: cover;
        width: 60px;
        height: 60px;

        display: inline-block;

        z-index: 10;
      }
    }
  }
}