/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
    display: block;
    height: calc(100vh - 50px);
    @include bp-medium() {
        height: calc(100vh - 80px);
    }
    position: relative;
    background: {
        size: cover;
        position: center;
        repeat: no-repeat;
    }
}
.c-header__wrapper {
    padding-top: 50px;

    @include bp-medium() {
        padding-top: 80px;
    }
}

.c-header-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    line-height: 1.4;

    .c-header-logo {
        padding: 0;
        margin-bottom: 30px;
    }
}

.c-header-post-type {
    font-size: 2rem;
    color: $color-white;
    font-weight: 100;
}

.c-header-title {
    font-size: 2rem;
    color: $color-white;
}

.c-header-scroll {
    position: absolute;
    bottom: 25px;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    img {
        width: 30px;
        display: block;
        margin: 0 auto;
    }
}

.c-header-scroll--text {
    font-size: 0.8em;
    color: $color-white;
}

.c-header-scroll--action {
    text-decoration: none;
    display: inline-block;
}

.c-header-mini-title {
    position: absolute;
    z-index: 2;
    bottom: 95px;
    text-align: center;
    width: 100%;
    h1 {
        font-weight: 100;
        color: $color-white;
        font-size: 30px;
    }
}

.scroll-down {
    animation: moveMouse 2s infinite;

    &:hover {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
    }
}

@keyframes moveMouse {
    0% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}