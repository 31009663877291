.c-news {
    padding: 60px 20px;
}

.c-news-title {
    text-align: center;
}

.c-news-grid {
    margin-top: 40px;
    @include clearfix();
}

.c-news-calendar {
    width: 100px;
    float: left;
    position: relative;
}

.c-news-icon-calendar {
    width: 70px;
    padding-left: 10px;

}

.c-news-grid-item {
    @include clearfix();
    border-bottom: 1px solid $color-red;
    padding-bottom: 30px;
    margin-top: 30px;
}

.c-news-content {
    @include clearfix();

    width: calc(100% - 100px);
    float: left;

    .c-btn {
        float: right;
        margin-top: 20px;
    }
}

.c-news-calendar-date {
    position: absolute;
    top: 25px;
    left: 18px;
    color: $color-red;
}

.c-news-content-title {
    display: block;
    font-weight: 700;
    color: $color-red;
    text-transform: uppercase;
}